
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.broker-card,
[class*='broker-card--'] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 31rem;
  padding: 4rem;
  border: 1px solid $elantis-blue;
  border-radius: 8px;
}

.broker-card__title {
  // prettier-ignore
  @include fluid(font-size,(xxs: 24px, xxl: 36px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 18px, xxl: 30px));

  color: $elantis-blue;
}

.broker-card__type {
  font-size: 1.4rem;

  &.ch {
    color: $elantis-blue;
  }

  &.pat {
    color: $elantis-turquoise;
  }
}

.broker-card__map {
  display: flex;
  align-items: center;
  color: $elantis-blue;

  img {
    margin-right: 1rem;
  }
}

.broker-card__type,
.broker-card__text,
.broker-card__map {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 20px, xxl: 25px));
}
