
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































.recap,
[class*='recap--'] {
}

.recap__broker {
  color: $c-gray-darker;
}
