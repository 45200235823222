
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.switcher,
[class*='switcher--'] {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 1;
  opacity: 0;
  pointer-events: none;

  &::before {
    @include center-y;

    content: '';
    z-index: -1;
    width: 100%;
    height: 1px;
    background: $c-gray-medium;
  }

  &.is-ready {
    opacity: 1;
    pointer-events: auto;
  }

  @include mq($until: m) {
    &::before {
      display: none;
    }
  }
}

.switcher-inner {
  display: flex;
  background: $c-white;
  border: 1px solid $c-gray-medium;
  border-radius: 8px;
}

.switcher__slider {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 10rem;
  height: 100%;
  color: $c-white;

  &::before {
    @include get-all-space;

    content: '';
    z-index: -1;
    overflow: hidden;
    background: $elantis-blue;
    border-radius: 8px;
    transform: scale(1.05, 1.2);
  }
}

.switcher__btn {
  @extend %focusable;

  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline-offset: 0.6rem;

  &:not(:first-child) {
    border-left: 1px $c-gray-medium solid;
  }
}

.switcher__btn__label {
  color: $c-gray-medium;
  transition: opacity 0.15s;

  .is-active & {
    opacity: 0;
  }
}

.box {
  @extend %fw-medium;

  // prettier-ignore
  @include fluid(font-size,(xxs: 14px, xxl: 18px));
  // prettier-ignore
  @include fluid(padding-top,(xxs: 15px, xxl: 17px));
  // prettier-ignore
  @include fluid(padding-right,(xxs: 15px, xxl: 30px));
  // prettier-ignore
  @include fluid(padding-bottom,(xxs: 10px, xxl: 15px));
  // prettier-ignore
  @include fluid(padding-left,(xxs: 15px, xxl: 30px));

  white-space: nowrap;
}
