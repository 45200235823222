
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















.radio {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  @extend %visually-hidden;

  border: 0;

  &:checked + label::after {
    display: block;
  }
}

.label {
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  color: #404040;
  font-size: 1.4rem;
  line-height: normal;

  &::before {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0;
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid $tundora;
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.8rem;
    left: 0.4rem;
    display: none;
    width: 1.2rem;
    height: 1.2rem;
    background: $c-turquoise-medium;
    border-radius: 50%;
  }
}
