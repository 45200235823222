
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































































.payment-proof,
[class*='payment-proof--'] {
  gap: 3.5rem;
  width: max(25rem, 50vw);
}

.payment-proof,
.payment-proof__header {
  display: flex;
  flex-direction: column;
}

.payment-proof__header {
  gap: 2rem;
}

.payment-proof__comment {
  .h3 {
    margin-bottom: 1rem;
    color: $c-black;
  }
}

.payment-proof__nav {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
