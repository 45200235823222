
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































































.docs,
[class*='docs--'] {
}

.docs__intro {
  a {
    color: $elantis-blue;
  }
}

.docs__minfin {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $tundora;

  a {
    color: $elantis-blue;
  }
}

.docs__nodoc {
  ::v-deep a {
    color: $elantis-orange;
  }
}
