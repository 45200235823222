
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































.management,
[class*='management--'] {
}

.management__contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  padding: 4rem;
  border: 1px solid $elantis-blue;
  border-radius: 0.8rem;

  h3 {
    color: $tundora;
  }
}

.management__contact__title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: $elantis-blue;
  font-size: 1.4rem;

  strong {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 3rem; /* 83.333% */
  }
}

.management__contact__link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
