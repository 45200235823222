
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































.download-list,
[class*='download-list--'] {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  border-top: 1px solid $elantis-grey-light;

  li {
    list-style-type: none;
  }
}

.download-list__item {
  display: flex;
  align-items: center;
  padding: 2.5rem 0;
  text-decoration: none;
  border-bottom: 1px solid $elantis-grey-light;

  &:hover {
    color: $elantis-blue;
  }

  &.is-disabled {
    pointer-events: none;
  }
}

.list__item__cta {
  b {
    display: none;
    color: $international-orange;
  }

  .has-error & {
    border-color: $international-orange;

    span {
      display: none;
    }

    b {
      display: block;
    }
  }
}

.download-list__type {
  // prettier-ignore
  @include fluid(width,(xxs: 20px, xxl: 28px));
  // prettier-ignore
  @include fluid(height,(xxs: 20px, xxl: 28px));

  margin-right: 1rem;
  fill: $c-gray-darker;

  .download-list__item:hover & {
    fill: $elantis-blue;
  }
}

.download-list__item__link a,
.download-list__item__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
  color: $elantis-blue;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;

  svg {
    // prettier-ignore
    @include fluid(width,(xxs: 20px, xxl: 28px));
    // prettier-ignore
    @include fluid(height,(xxs: 20px, xxl: 28px));
  }

  .is-disabled & {
    color: $elf-green;

    svg {
      fill: $elf-green;
    }
  }
}
