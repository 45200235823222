
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































.upload,
[class*='upload--'] {
}

.upload__label {
  margin-bottom: 1rem;
}

.upload__zone {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  margin-bottom: 3rem;
  padding: 6rem 3rem;
  text-align: center;
  border: 4px dashed $c-gray-medium;
  border-radius: 1.6rem;

  ::v-deep {
    .filepond--credits {
      display: none;
    }

    .filepond--root {
      @include get-all-space;

      z-index: 100;
      /* stylelint-disable-next-line declaration-no-important */
      height: 100% !important;
      margin-bottom: 0;
      opacity: 0;
    }

    .filepond--list {
      display: none;
    }

    .filepond--drop-label {
      @include get-all-space;
    }
  }
}

.upload__zone__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.upload__zone__block__icon {
  width: 4.8rem;
  height: 4.8rem;
}

.upload__files {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.upload__files__item {
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  color: $elantis-blue;
  font-weight: 500;
  line-height: 2rem;

  .error,
  .success {
    display: none;
  }

  &.success .success {
    display: block;
  }

  &.error .error {
    display: block;
  }

  &.success .loading-bar,
  &.error .loading-bar {
    display: none;
  }
}

.upload__files__item__icon {
  position: relative;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;

  svg {
    display: block;
    fill: $elantis-blue;
  }
}

.upload__files__item__remove {
  @include get-all-space;

  display: none;
  align-items: center;
  padding: 0;
  background-color: $c-white;
  border: 0;
  fill: $elantis-orange;
  cursor: pointer;

  .upload__files__item__icon:hover & {
    display: flex;
  }
}

.upload__files__item__name {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.upload__files__item__state {
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;

  .error {
    color: $elantis-orange;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.loading-bar {
  width: 6rem;
  min-height: 2rem;
  background-color: #00ab89;
  background-image: linear-gradient(
    45deg,
    transparent,
    transparent 33.33%,
    #36d0b2 33.33%,
    #36d0b2 66.66%,
    transparent 66.66%,
    transparent
  );
  border-radius: 20px;
  will-change: background-position;
  animation: slide 2s linear infinite;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 6rem;
  }
}
