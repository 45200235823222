
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































































































































































.single-loan,
[class*='single-loan--'] {
}

.single-loan__nav {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  color: $elantis-blue;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.5rem;
  }
}

.single-loan__title {
  margin-bottom: 1rem;
}

.single-loan__intro {
  a {
    color: $elantis-blue;
  }
}

.single-loan__check-icon {
  // prettier-ignore
  @include fluid(width,(xxs: 20px, xxl: 32px));
  // prettier-ignore
  @include fluid(height,(xxs: 20px, xxl: 32px));

  margin: 0 1rem 0 0;
  vertical-align: middle;
  fill: $c-green;
}

.single-loan__minfin {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $tundora;

  a {
    color: $elantis-blue;
  }
}
