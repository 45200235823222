
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































































































































.payments,
[class*='payments--'] {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  h2 {
    color: $c-gray-darker;
  }
}

.payments__header,
.payments__header__status {
  display: flex;
  gap: 2rem;
}

.payments__header {
  flex-direction: column;
}

.payments__header__status {
  align-items: center;
}

.payments__header__balance {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem;
}

.payments__header__balance__date {
  color: $c-black;
}

.payments__header__balance__amount {
  color: $elantis-blue;
  font-size: 3.6rem;
  line-height: 3.6rem; /* 100% */
}

.payments__list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.payments__list__actions,
.payments__list__actions__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payments__list__actions__title {
  @extend %fw-medium;

  color: $elantis-blue;
  font-size: 1.4rem;
  line-height: 2rem; /* 142.857% */
}

.payments__list__actions__content {
  align-items: flex-start;

  @include mq(m) {
    gap: 6rem;
    flex-direction: row;
  }
}

.payments__list__actions__content__cta {
  flex-shrink: 0;
  border: 0;

  ::v-deep .download-list__item {
    padding: 0;
    border: 0;
  }
}

.payments-table-outer {
  @include scrollbars;

  overflow: auto;
  margin: 2rem 0 0;
}

.payments-table {
  width: 100%;
  border-collapse: collapse;
}

.payments-table__th {
  color: $c-gray-darker;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5rem;

  // &:last-child {
  //   span {
  //     display: block;
  //     padding-left: 1rem;
  //   }

  //   span::after {
  //     @include get-all-space;

  //     content: '';
  //     border-left: 10px solid;
  //     border-image: linear-gradient(to right, transparent, rgba($c-black, 0.1)) 1 100%;
  //   }
  // }
}

.payments-table__td {
  white-space: nowrap;
}

.payments-table__th,
.payments-table__td {
  position: relative;
  min-width: 13rem;
  padding: 2rem 3rem 2rem 0;
  text-align: left;
  background-color: $c-white;
  border: solid $elantis-grey-light;
  border-width: 2px 0;

  // &:last-child {
  //   position: sticky;
  //   top: 0;
  //   right: 0;
  //   min-width: auto;
  //   text-align: center;
  // }
}

// .payments-table-options {
//   @include get-all-space;

//   top: -2px;
//   bottom: -2px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: auto;
//   padding: 0;
//   background-color: transparent;
//   border: 0;
//   border-left: 10px solid;
//   border-image: linear-gradient(to right, transparent, rgba($c-black, 0.1)) 1 100%;
//   cursor: pointer;
// }
