
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































































































































.reimbursement,
.reimbursement__header,
.reimbursement__radio {
  display: flex;
  flex-direction: column;
}

.reimbursement {
  gap: 3.5rem;
  background-color: $c-white;
}

.reimbursement__header {
  gap: 2rem;
}

.reimbursement__radio {
  gap: 1.5rem;
}

.reimbursement__nav {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.reimbursement__category {
  color: $c-black;
}
